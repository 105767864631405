<template>
  <div v-loading="loading.global" class="body no-select">
    <div class="head-box">
      <!-- 头部 -->
      <div class="to-page-btn"><el-button type="primary" size="small" @click="onToWeb" icon="el-icon-s-home"></el-button></div>
      <div class="head-title">智能监控数据大屏</div>
      <div class="head-timer" id="timer">{{ nowTime }}</div>
    </div>
    <div class="main-box">
      <div class="left-box">
        <!-- 左侧边栏，列表 -->
        <div class="data-screen-select">
          <el-form>
            <el-form-item label="当前省份">
              <el-select v-model="selectedProv" size="mini" @change="onSelectProv">
                <el-option v-for="(prov, index) in provList" :key="index" :label="prov" :value="prov"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="left-list-box">
          <div class="frame-title-box frame-txt-title-box">设备在线时间</div>
          <div class="frame-data-box left-table-box" style="height: calc(100% - 5.3vh);" v-loading="loading.devices">
            <el-table :data="deviceList"  style="width: 100%;">
              <el-table-column prop="information" width="150" label="设备名称">
                <template slot-scope="scope">
                  {{ scope.row.information || scope.row.code }}
                </template>
              </el-table-column>
              <el-table-column prop="hours" label="运行时间(小时)" min-width="110"></el-table-column>
              <el-table-column prop="createTime" label="上线时间" min-width="150"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="center-box">
        <!-- 中间，地图 -->
        <div class="map-box">
          <div ref="mapChart" :style="`width:${map.width}px; height:${map.height}px`"></div>
        </div>
        <div class="device-statistic-box">
          <div class="device-statistic-title-box">设备统计</div>
          <div class="device-statistic-val-box">
            <div>
              <div>所有设备</div><div>{{ deviceCnts.total }}</div>
            </div>
            <div>
              <div>在线设备</div><div>{{ deviceCnts.online }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-box">
        <!-- 右侧边栏，在线率、告警率、告警列表 -->
        <div class="right-data-box" :style="`height:calc(${right.height}px + 8vh); margin-bottom:8vh;`">
            <div class="frame-title-box frame-txt-title-box">设备在线率</div>
            <div class="frame-data-box">
              <div :style="`height:calc(${right.height}px + 8vh);`">
                <div ref="deviceOnlineChart" :style="`width:${right.width - 265}px; height:calc(${right.height}px + 5vh);`"></div>
              </div>
              <div class="right-legend-box">
                <div class="device-base-data-box"><div><div class="device-online-box"></div></div><div>在线</div></div>
                <div class="device-base-data-box"><div>在线数量</div><div>{{ deviceCnts.online }} 台</div></div>
                <div class="device-base-data-box"><div>离线数量</div><div>{{ deviceCnts.offline }} 台</div></div>
              </div>
            </div>
        </div>
        <div class="right-data-box" :style="`height:calc(${right.height}px + 8vh); margin-bottom:7vh;`">
            <div class="frame-title-box frame-txt-title-box">设备告警率</div>
            <div class="frame-data-box">
              <div :style="`height:calc(${right.height}px + 8vh);`">
                <div ref="deviceWarningChart" :style="`width:${right.width - 265}px; height:calc(${right.height}px + 5vh);`"></div>
              </div>
              <div class="right-legend-box">
                <div class="device-base-data-box"><div><div class="device-warning-box"></div></div><div>告警</div></div>
                <div class="device-base-data-box"><div>告警数量</div><div>{{ deviceCnts.warning }} 台</div></div>
                <div class="device-base-data-box"><div>设备数量</div><div>{{ deviceCnts.total }} 台</div></div>
              </div>
            </div>
        </div>
        <div class="right-last-box" :style="`height:calc(100% - (${right.height}px + 8vh) * 2 - 15vh);`">
          <div style="100%">
            <div>
              <div class="frame-txt-title-box">告警信息</div>
            </div>
            <div style="100%">
              <div style="overflow: hidden; height:calc(100% - 10px);">
                <vue-seamless-scroll :data="warningData" class="seamless-warp" :class-option="classOption">
                <el-table :data="warningData" style="width: 100%; height: 100%;" :show-header="false">
                  <el-table-column prop="productId" width="70"></el-table-column>
                  <el-table-column prop="createTime" width="155"></el-table-column>
                  <el-table-column prop="warningData">
                    <template slot-scope="scope">
                      <div v-if="scope.row.warning == null"></div>
                      <div v-else-if="scope.row.warning.length > 0">
                        <span v-if="scope.row.warning[0]">总压过高一级告警 </span>
                        <span v-if="scope.row.warning[1]">总压过高二级告警 </span>
                        <span v-if="scope.row.warning[2]">总压过高三级告警 </span>
                        <span v-if="scope.row.warning[3]">总压过低一级告警 </span>
                        <span v-if="scope.row.warning[4]">总压过低二级告警 </span>
                        <span v-if="scope.row.warning[5]">总压过低三级告警 </span>
                        <span v-if="scope.row.warning[6]">单体过高一级告警 </span>
                        <span v-if="scope.row.warning[7]">单体过高二级告警 </span>
                        <span v-if="scope.row.warning[8]">单体过高三级告警 </span>
                        <span v-if="scope.row.warning[9]">单体过低一级告警 </span>
                        <span v-if="scope.row.warning[10]">单体过低二级告警 </span>
                        <span v-if="scope.row.warning[11]">单体过低三级告警 </span>
                        <span v-if="scope.row.warning[12]">高段压差过高一级告警 </span>
                        <span v-if="scope.row.warning[13]">高段压差过高二级告警 </span>
                        <span v-if="scope.row.warning[14]">高段压差过高三级告警 </span>
                        <span v-if="scope.row.warning[15]">中段压差过高一级告警 </span>
                        <span v-if="scope.row.warning[16]">中段压差过高二级告警 </span>
                        <span v-if="scope.row.warning[17]">中段压差过高三级告警 </span>
                        <span v-if="scope.row.warning[18]">低段压差过高一级告警 </span>
                        <span v-if="scope.row.warning[19]">低段压差过高二级告警 </span>
                        <span v-if="scope.row.warning[20]">低段压差过高三级告警 </span>
                        <span v-if="scope.row.warning[21]">辅助压差过高一级告警 </span>
                        <span v-if="scope.row.warning[22]">辅助压差过高二级告警 </span>
                        <span v-if="scope.row.warning[23]">辅助压差过高三级告警 </span>
                        <span v-if="scope.row.warning[24]">充电电流过高一级告警 </span>
                        <span v-if="scope.row.warning[25]">充电电流过高二级告警 </span>
                        <span v-if="scope.row.warning[26]">充电电流过高三级告警 </span>
                        <span v-if="scope.row.warning[27]">放电电流过高一级告警 </span>
                        <span v-if="scope.row.warning[28]">放电电流过高二级告警 </span>
                        <span v-if="scope.row.warning[29]">放电电流过高三级告警 </span>
                        <span v-if="scope.row.warning[30]">反充电流过高一级告警 </span>
                        <span v-if="scope.row.warning[31]">反充电流过高二级告警 </span>
                        <span v-if="scope.row.warning[32]">反充电流过高三级告警 </span>
                        <span v-if="scope.row.warning[33]">SOC值过低一级告警 </span>
                        <span v-if="scope.row.warning[34]">SOC值过低二级告警 </span>
                        <span v-if="scope.row.warning[35]">SOC值过低三级告警 </span>
                        <span v-if="scope.row.warning[36]">SOH值过低一级告警 </span>
                        <span v-if="scope.row.warning[37]">SOH值过低二级告警 </span>
                        <span v-if="scope.row.warning[38]">SOH值过低三级告警 </span>
                        <span v-if="scope.row.warning[39]">继电器温度过高一级告警 </span>
                        <span v-if="scope.row.warning[40]">继电器温度过高二级告警 </span>
                        <span v-if="scope.row.warning[41]">继电器温度过高三级告警 </span>
                        <span v-if="scope.row.warning[42]">充电温度过高一级告警 </span>
                        <span v-if="scope.row.warning[43]">充电温度过高二级告警 </span>
                        <span v-if="scope.row.warning[44]">充电温度过高三级告警 </span>
                        <span v-if="scope.row.warning[45]">放电温度过高一级告警 </span>
                        <span v-if="scope.row.warning[46]">放电温度过高二级告警 </span>
                        <span v-if="scope.row.warning[47]">放电温度过高三级告警 </span>
                        <span v-if="scope.row.warning[48]">充电温度过低一级告警 </span>
                        <span v-if="scope.row.warning[49]">充电温度过低二级告警 </span>
                        <span v-if="scope.row.warning[50]">充电温度过低三级告警 </span>
                        <span v-if="scope.row.warning[51]">放电温度过低一级告警 </span>
                        <span v-if="scope.row.warning[52]">放电温度过低二级告警 </span>
                        <span v-if="scope.row.warning[53]">放电温度过低三级告警 </span>
                        <span v-if="scope.row.warning[54]">温差过大一级告警 </span>
                        <span v-if="scope.row.warning[55]">温差过大二级告警 </span>
                        <span v-if="scope.row.warning[56]">温差过大三级告警 </span>
                        <span v-if="scope.row.warning[57]">均衡温度过高一级告警 </span>
                        <span v-if="scope.row.warning[58]">均衡温度过高二级告警 </span>
                        <span v-if="scope.row.warning[59]">均衡温度过高三级告警 </span>
                        <span v-if="scope.row.warning[60]">绝缘电阻过低一级告警 </span>
                        <span v-if="scope.row.warning[61]">绝缘电阻过低二级告警 </span>
                        <span v-if="scope.row.warning[62]">绝缘电阻过低三级告警 </span>
                        <span v-if="scope.row.warning[63]">总压采样异常一级告警 </span>
                        <span v-if="scope.row.warning[64]">总压采样异常二级告警 </span>
                        <span v-if="scope.row.warning[65]">总压采样异常三级告警 </span>
                        <span v-if="scope.row.warning[66]">静置温度过高一级告警 </span>
                        <span v-if="scope.row.warning[67]">静置温度过高二级告警 </span>
                        <span v-if="scope.row.warning[68]">静置温度过高三级告警 </span>
                        <span v-if="scope.row.warning[69]">静置温度过低一级告警 </span>
                        <span v-if="scope.row.warning[70]">静置温度过低二级告警 </span>
                        <span v-if="scope.row.warning[71]">静置温度过低三级告警 </span>
                        <span v-if="scope.row.warning[72]">主正接触器无法吸合 </span>
                        <span v-if="scope.row.warning[73]">主正接触器粘连故障 </span>
                        <span v-if="scope.row.warning[74]">主负接触器无法吸合 </span>
                        <span v-if="scope.row.warning[75]">主负接触器粘连故障 </span>
                        <span v-if="scope.row.warning[76]">充电接触器无法吸合 </span>
                        <span v-if="scope.row.warning[77]">充电接触器粘连故障 </span>
                        <span v-if="scope.row.warning[78]">预热接触器无法吸合 </span>
                        <span v-if="scope.row.warning[79]">预热接触器粘连故障 </span>
                        <span v-if="scope.row.warning[80]">风扇接触器无法吸合 </span>
                        <span v-if="scope.row.warning[81]">风扇接触器粘连故障 </span>
                        <span v-if="scope.row.warning[82]">预充接触器无法吸合 </span>
                        <span v-if="scope.row.warning[83]">预充接触器粘连故障 </span>
                      </div>
                    <div v-else>设备无告警</div>
                  </template>
                  </el-table-column>
                </el-table>
              </vue-seamless-scroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import {  getDevices, getDevicesWarngingList } from '@/api/device';
// import { parseWarningData } from "@/utils/device";
import { getDataAsArea, getStatusCount, getNationwideCount } from "@/api/screen";

import "./china.js";

const GEO_COORD_MAP = {
  '西藏':[91.11,29.97],
  '上海':[121.48,31.22],
  '广西':[108.33,22.84],
  '广东':[113.68,22.76],
  '云南':[102.73,25.04],
  '香港':[114.07,22.42],
  '澳门':[113.52,22.1],
  '海南':[110.35,19.92],
  '辽宁':[123.38,41.8],
  '吉林':[125.35,43.88],
  '宁夏':[106.27,38.47],
  '青海':[101.74,36.56],
  '内蒙古':[111.65,40.82],
  '四川':[104.06,30.67],
  '陕西':[108.95,34.27],
  '重庆':[106.54,29.59],
  '江苏':[118.98,32.04],
  '贵州':[106.71,26.57],
  '北京':[116.46,39.92],
  '新疆':[87.68,43.77],
  '浙江':[120.19,30.26],
  '山东':[117,36.65],
  '甘肃':[103.73,36.03],
  '天津':[117.2,39.13],
  '河南':[113.65,34.76],
  '黑龙江':[126.63,45.75],
  '河北':[114.48,38.03],
  '湖南':[113,28.21],
  '安徽':[117.27,31.86],
  '湖北':[114.31,30.52],
  '山西':[112.549,37.8571],
  '福建':[119.28,26.08],
  '江西':[115.78,28.30],
  '台湾':[121.51,25.01]
};

function convertData(data) {
  var res = [];
  for (var i = 0; i < data.length; i++) {
    var geoCoord = GEO_COORD_MAP[data[i].name];
    if (geoCoord) {
      res.push({
        name: data[i].name,
        value: geoCoord.concat(data[i].value)
      });
    }
  }
  return res;
};

export default {
  components: {
    vueSeamlessScroll
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 5, // 开始无缝滚动的数据量
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 500, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  data () {
    return {
      loading: {
        global: true,
        devices: true
      },
      times: true,
      mapTitle: "所有设备",
      map: {
        width: 0,
        height: 0
      },
      right: {
        width: 0,
        height: 0
      },
      history: {
        width: 0,
        height: 0
      },
      bottom: {
        width: 0,
        height: 0
      },
      timer: {
        map: null
      },
      warningData: [],
      deviceList: [],
      is2k: false,
      isOver2k: false,
      isLessHD: false,
      isHD: false,
      isOverHD: false,
      isFHD: false,
      isOverFHD: false,
      warningLength: 0,
      user: null,
      businessId: undefined,
      nowTime: $utils.formatTime(),
      deviceCnts: {
        online: 0,
        offline: 0,
        total: 0,
        normal: 0,
        warning: 0
      },
      selectedProv: "全国",
      provList: ["全国"].concat(Object.keys(GEO_COORD_MAP)),
      paging: {
        current: 1,
        size: 0,
        total: 0
      }
    }
  },
  created () {

    this.user = JSON.parse(sessionStorage.getItem('USER'));

    this.map.width = document.body.scrollWidth * 0.42;
    this.map.height = document.body.scrollWidth * 0.32;
    this.right.width = document.body.scrollWidth * 0.25 - 30;
    this.right.height = document.body.scrollHeight * 0.9 * 0.20 + 30

    this.isLessHD = window.outerWidth < 1280;
    this.isHD = window.outerWidth === 1280;
    this.isOverHD = window.outerWidth > 1280;
    this.isFHD = window.outerWidth > 1280 && window.outerWidth <= 1920;
    this.isOverFHD = window.outerWidth > 1920;
    this.is2k = window.outerWidth > 1920 && window.outerWidth <= 2560;
    this.isOver2k = window.outerWidth > 2560;

    this.warningLength = 5;
    if (this.HD) {
      this.paging.size = 7;
    } else if (this.isFHD) {
      this.paging.size = 10;
    } else if (this.is2k) {
      this.paging.size = 20;
    }

    if (window.innerHeight >= window.outerHeight) {
      this.paging.size += 2;
    }

    // getFiveArea();
  },
  async mounted () {
    this.businessId =  this.user.tenantId === 0 ? undefined : this.user.businessId;

    this.startTimer();
    try {
      await Promise.all([
        this.getDatas(),
        this.getStatusCount(),
        this.getDevices()
      ]);

      if (this.deviceCnts.online > 0) {
        this.getDevicesWarngingList();
      }

    } catch (e) {
      console.log(e);
    } finally {
      this.loading.global = false;
    }
  },
  methods: {
    startTimer() {
      if (!$utils.isDevelopMode()) {
        setInterval(() => {
          this.nowTime = $utils.formatTime()
        }, 100);
      }
    },
    onToWeb () {
      window.open("/#/device");
    },
    onSelectProv (arg) {
      this.selectedProv = arg;
      this.getDevices1();
    },
    async getDevices () {
      this.getDevices1();
    },
    async getDevices1 () {

      this.loading.devices = true;

      try {
        const params = {
          page: this.paging.current,
          size : this.paging.size,
          // onlineStatus: 1,
          area: this.selectedProv === "全国" ? undefined : this.selectedProv,
          businessId: this.businessId
        }
        const res = await getDevices(params);
        const { data, paging } = res;

        const now = Date.now();
        const list = data.map(obj => {
          const createTime = new Date(obj.gmtCreate[0], obj.gmtCreate[1] - 1, obj.gmtCreate[2], obj.gmtCreate[3], obj.gmtCreate[4], obj.gmtCreate[5] || 0);
          const start = createTime.getTime();
          const hours = (now - start) / 1000 / 3600;
          obj.information = obj.information ? obj.information.slice(0, 8) : obj.code;
          obj.hours = hours.toFixed(2);
          obj.createTime = $utils.formatTime(createTime);
          return obj;
        });

        if (list.length > 0 && list.length < this.paging.size) {
          for (let i = list.length; i < this.paging.size; i++) {
            list.push({
              information: "\uFEFF",
              createTime: "\uFEFF",
              hours: "\uFEFF"
            });
          }
        }

        this.deviceList = list;
        this.paging = paging;

        if (this.paging.current++ * this.paging.size >= this.paging.total) {
          this.paging.current = 1;
        }

      } catch (e) {
        console.log(e);
      } finally {
        this.loading.devices = false;
      }
    },
    async getDatas() {
      await this.getDatas1();
    },
    async getDatas1 () {
      const [ alls, onlines ] = await Promise.all([
        getDataAsArea(null, this.businessId),
        getNationwideCount(undefined, this.businessId)
      ]);

      const tOnline = {};
      for (const key in onlines) {
        const oline = onlines[key];
        if (oline.true > 0) {
          tOnline[key] = oline.true
        }
      }

      this.showMapData(alls, onlines);
      this.times = !this.times;
    },
    showMapData (d, onlines) {
      const data = $utils.getMapDataList();

      data.map(obj => {
        obj.value = (d[obj.name] || 0);
        let log = obj.value;
        log = log === 0 ? 0 : Math.log10(obj.value);
        log *= 100;
        obj.value = log;

      });

      const options = {
        tooltip: {
          trigger: 'item',
          formatter: function(params) {

            const name = params.data.name;
            const all = d[name] || 0;
            const online = (onlines[name] || {}).true || 0;

            return `<div style='text-align:left;"'><div>总量：${all}</div><div>在线：${online}</div></div>`;
          }
        },
        /*工具按钮组*/
        geo: {
          show: true,
          map: 'china',
          label: {
            normal: {
              show: false
            },
            emphasis: {
              show: false
            }
          },
          aspectScale:0.75, //长宽比,0.75的含义为宽是高的0.75,假如高为100,宽就只有75;0.5的含义就是宽只有高的一半,假如高为100,宽就只有50
          zoom:1.2, //视觉比例大小,1.2即为原有大小的1.2倍
          roam: false,//禁止其放大缩小
          itemStyle: {
            normal: {
              areaColor: 'rgba(35, 77, 205, 0.3)', // '#234dcd'
              borderColor: 'rgba(90, 245, 245, 0.7)',
              shadowBlur: 10,
              borderWidth: 2,
              borderShadow: 5,
              shadowColor: '#eafaff',
            },
            emphasis: {
              areaColor: 'rgba(50, 135, 202, 0.3)', // #2bdaff'
              borderColor: 'rgba(100, 150, 245, 0.6)'
            }
          }
        },
        series: [{
          name: '散点',
          type: 'scatter',
          coordinateSystem: 'geo',
          data: convertData(data),
          symbolSize: function(val) {
            return val[2] / 10;
          },
          itemStyle: {
            normal: {
              color: 'rgba(255,255,0,0.8)'
            }
          }
        },
        {
          name: '大地图',
          type: 'effectScatter',
          coordinateSystem: 'geo',
          data: convertData(data.sort(function(a, b) {
            return b.value - a.value;
          })),
          symbolSize: function(val) {
            return val[2] / 10;
          },
          showEffectOn: 'render',
          rippleEffect: {
            brushType: 'stroke'
          },
          hoverAnimation: true,
          label: {
            normal: {
              formatter: ' {b}',
              position: 'right',
              show: true
            }
          },
          itemStyle: {
            normal: {
              color: 'rgba(255,255,0,0.8)',
              shadowBlur: 10,
              shadowColor: '#05C3F9'
            }
          },
          zlevel: 1
        }]
      };

      const mapChart = echarts.init(this.$refs.mapChart);
      mapChart.clear();

      mapChart.on("click", (arg) => {
        this.selectedProv = arg.name;
        this.getDevices1();
      });

      mapChart.setOption(options);
    },
    async getStatusCount () {
      const res = await getStatusCount(undefined, this.businessId);

      let online = res[1].true || 0, warning = res[0].true || 0, offline = res[1].false || 0;
      let total = online + offline, normal = online - warning;

      if (online < warning) {
        warning = 0;
        normal = 0;
      }

      this.deviceCnts = {
        online, warning, offline, total, normal
      }

      this.initOnlineChart(online, warning, offline, total, normal);
      this.initWarningChart(online, warning, offline, total, normal);
    },
    initOnlineChart (online, warning, offline, total, normal) {
      const deviceOnlineChart = echarts.init(this.$refs.deviceOnlineChart);

      const value = total == 0 ? 0: (online / total * 100).toFixed(1); //图上角度数据
      const options = {
        legend: {
          top: '5%',
          orient: 'vertical',
          right: "10",
          top: 'center',
          textStyle: {
            fontSize: 15,
            color: '#ffffff'
          }
        },
        title: {
          text: '{a|'+ value +'}{c| %}',
          x: 'center',
          y: 'center',
          textStyle: {
            rich:{
              a: {
                fontSize: this.isOverHD ? 25 : 15,
                color: '#19f3c3',
                fontWeight: 'bold'
              },
              c: {
                fontSize: this.isOverHD ? 25 : 15,
                color: '#19f3c3',
                fontWeight: 'bold'
              }
            }
          }
        },
        series: [
          //外环
          {
            name: "外环",
            type: 'pie',
            silent: true,
            clockwise: true,
            radius: ['63.5%', '65%'],
            label: {
              show: false,
            },
            labelLine: {
              show: false
            },
            itemStyle: {
              color: 'rgba(29, 234, 193, 0.6)',
            },
            data: [0]
          },
          //内环
          {
            name: '',
            type: 'pie',
            radius: ['45%', '58%'],
            silent: true,
            clockwise: true,
            startAngle: 90,
            label: {
              show: false,
            },
            data: [
              {
                value: value,
                itemStyle: {
                  normal: {
                    color: { // 圆环的颜色
                      colorStops: [{
                        offset: 0,
                        color: '#98f9e1', // 0% 处的颜色
                      }, {
                        offset: 1,
                        color: '#5ea22c', // 100% 处的颜色
                      }]
                    },
                  }
                }
              },
              {
                value: 100-value,
                label: {
                  normal: {
                    show: false
                  }
                },
                itemStyle: {
                  normal: {
                    color: "#364662"
                  }
                }
              }
            ]
          },
        ]
      };

      deviceOnlineChart.setOption(options);
    },
    initWarningChart (online, warning, offline, total, normal) {
      const deviceWarningChart = echarts.init(this.$refs.deviceWarningChart);

      const value = total == 0 ? 0: (warning / total * 100).toFixed(1); //图上角度数据
      const options = {
        title: {
          text: '{a|'+ value +'}{c| %}',
          x: 'center',
          y: 'center',
          textStyle: {
            rich:{
              a: {
                fontSize: this.isOverHD ? 25 : 15,
                color: '#ff4457',
                fontWeight: 'bold'
              },
              c: {
                fontSize: this.isOverHD ? 25 : 15,
                color: '#ff4457',
                fontWeight: 'bold'
              }
            }
          }
        },
        series: [
          //外环
          {
            name: "外环",
            type: 'pie',
            silent: true,
            clockwise: true,
            radius: ['63.5%', '65%'],
            label: {
              show: false,
            },
            labelLine: {
              show: false
            },
            itemStyle: {
              color: 'rgba(255, 78, 78, 0.6)',
            },
            data: [0]
          },
          //内环
          {
            name: '',
            type: 'pie',
            radius: ['45%', '58%'],
            silent: true,
            clockwise: true,
            startAngle: 90,
            label: {
              show: false,
            },
            data: [
              {
                value: value,
                  itemStyle: {
                    normal: {
                      color: { // 圆环的颜色
                        colorStops: [{
                          offset: 0,
                          color: '#ffb1b1', // 0% 处的颜色
                        }, {
                          offset: 1,
                          color: '#cd0a0a', // 100% 处的颜色
                        }]
                      },
                    }
                  }
                },
              {
                value: 100 - value,
                label: {
                  normal: {
                    show: false
                  }
                },
                itemStyle: {
                  normal: {
                    color: "#364662"
                  }
                }
              }
            ]
          },
        ]
      };

      deviceWarningChart.setOption(options);
    },
    async getDevicesWarngingList() {
      this.getDevicesWarngingList1();
      setInterval(this.getDevicesWarngingList1, 30000);
    },
    async getDevicesWarngingList1 () {
      const res = await getDevicesWarngingList(this.businessId);

      const wObj = res.map(({ deviceName, productId, createTime, warning }) => {

        if (warning.length < 84) {
          for (let i = warning.length; i < 84; i++) {
            warning = "0" + warning;
          }
        }

        const { warningData, count } = $utils.parseWarningData(warning);

        return {
          deviceName,
          productId, createTime,
          warning: count > 0 ? warningData: []
        }
      });

      this.warningData = wObj;
    }
  }
}
</script>
<style lang="scss" scoped>
@font-face {
  font-family:ElectronicFont;
  src:url(../../../assets/font/DS-DIGIT.TTF)
}

.body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover !important;
  background: url("../../../assets/images/screen//wb671.jpg");

  .head-box {
    text-align: center;
    color:#FFFFFF;
    font-weight: bold;
    width: 100%;
    height: 10.5vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover !important;
    background: url("../../../assets/images/screen//wb679.png");

    .to-page-btn {
      position: absolute;
      top: 1vh;
      left: 1vh;
    }

    .head-timer {
      position: absolute;
      top: 1.5vh;
      right: 1vh;
      font-size: 16px;
    }

    .head-title {
      width: 100%;
      line-height: 8vh;
      text-align: center;
      font-size: 5vh;
    }
  }

  .main-box {
    width: 100%;
    height: calc(100% - 10.5vh);

    >div {
      display: inline-block;
      padding: 15px;
      padding-top: 0px;
      height: 100%;
      vertical-align: top;
    }

    .left-box {
      padding-right: 5px;
      width: 25.5%;

      .left-list-box {
        border-color: rgba($color: #2f93d1, $alpha: 0.4 );
        border-style: solid;
        border-width: 4px;

        padding: 0.6vh;
        height: calc(100% - 55px);

        .left-table-box {
          overflow: hidden;
        }
      }
    }
    .right-box {
      // 
      width: 29.5%;
      padding-top: 15px;
      padding-left: 5px;

      .right-data-box {
        margin-bottom: 15px;

        .right-legend-box {
          margin-top: -20px;
        }
      }

      .right-last-box {
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: contain !important;
        background-position-y: -3vh;
        background-image: url("../../../assets/images/screen//wb678-top.png");
        border-color: rgba(47, 147, 209, 0.4);
        border-style: solid;
        border-width: 4px;

        >div {
          background-repeat: no-repeat;
          background-size: 2.5vw 16vh !important;
          background-position-x: 2.3vw;
          background-image: url("../../../assets/images/screen//wb678-left.png");
          width: 100%;

          >div {
            display: inline-block;
            vertical-align: top;
          }
          >div:first-child {
            width: 3.66vw;
            writing-mode: vertical-rl;
            height: 100%;
            padding-top: 2.2vh;
            padding-right: 2.3vh;
            border-right-color: rgba($color: #2f93d1, $alpha: 0.4 );
            border-right-style: solid;
            border-right-width: 4px;
            background-color: rgba($color: #173e97, $alpha: 0.4);
          }

          >div:last-child {
            padding: 10px;
            width: calc(100% - 3.66vw);
          }
        }
      }

      .seamless-warp {
        overflow: hidden;
      }
    }

    .center-box {
      width: 45%;
      text-align: center;
      padding: 15 0px;

      .map-box {
        width: 43vw;
        height: 33vw;

        >div {
          margin: 4.5vh auto;
        }
      }

      .device-statistic-box {
        width:43vw;
        height:calc(100% - 34vw);
        padding-top: 3vh;
        color: #FFFFFF;
        text-align: center;
        font-size: 1vw;
        font-weight: bold;
        @media (height: 1440px) {
          margin-top: 4vh;
        }

        .device-statistic-title-box {
          width:30vw;
          height:3vw;
          line-height:3vw;
          background: linear-gradient(to right,rgba(0, 147, 244, 0), rgba(0, 147, 244, 0.5), rgba(0, 147, 244, 0));
          margin:auto;
        }

        .device-statistic-val-box {
          width: 43vw;
          margin: auto;
          margin-top: 4.3vh;

          @media (height: 1440px) {
            margin-top: 6vh;
          }

          >div {
            display: inline-block;
            height:8vh;
            line-height: 8.6vh;
            width: 21.5vw;
            background-size: 20vw 4.762vw !important;
            background-position-x: 1.1vw;
            background-repeat: no-repeat;
            >div {
              display: inline-block;
              vertical-align: middle;
              width: 50%;
              font-size: 1vw;
            }
            >div:first-child {
              padding-left: 5vw;
            }
            >div:last-child {
              font-family: ElectronicFont;
              text-align: right;
              padding-right: 1.5vw;
              font-size: 1.5vw;
              color: #ffeb7b;
            }
          }

          >div:first-child {
            background-image: url("../../../assets/images/screen/device-all-box-bg.png");
          }
          >div:last-child {
            background-image: url("../../../assets/images/screen/device-ol-box-bg.png");
          }
        }
      }
    }
  }
}

.frame-title-box {
  height: 5.3vh;
  line-height: 5.2vh;
  text-align: center;
  background-size: 100% 5.3vh;
  background-image: url("../../../assets/images/screen//wb676-top.png");
  background-repeat: no-repeat;
}

.frame-data-box {
  background-repeat: no-repeat;
  background-size: contain !important;
  background-position: bottom;
  background-image: url("../../../assets/images/screen//wb676-bottom.png");

  >div {
    display: inline-block;
    vertical-align: middle;
  }
  >div:last-child {
    width: 185px;
    color: #FFFFFF;
    padding-top: -15px;

    .device-base-data-box {
      margin-bottom: 10px;
      >div {
        display: inline-block;
      }
      >div:first-child {
        width: 75px;
      }

      .device-online-box, .device-warning-box {
        width: 30px;
        height: 15px;
      }

      .device-online-box {
        background: #19f3c3;
      }

      .device-warning-box {
        background: #ff4457;
      }
    }
  }
}

.frame-txt-title-box {
  color: rgb(116,200,213);
  letter-spacing: 15px;
  font-size: 1vw;
}

.el-table, .el-table__expanded-cell{
  background-color: transparent;
}

/* 表格内背景颜色 */
::v-deep .el-table th,
  ::v-deep .el-table tr,
::v-deep .el-table td {
  color: #FFFFFF;
  background-color:transparent;
}

.el-table::before {
  background: none;
}

::v-deep .el-table td.el-table__cell {
  border-bottom: 0px;
}

::v-deep .el-table tr:nth-child(odd) {
  background-color: rgba($color: #284d80, $alpha: 0.33);
}
::v-deep .el-table tr:nth-child(even) {
  background-color: rgba($color: #233e6a, $alpha: 0.33);
}

::v-deep .el-table tbody tr:hover>td { 
  background-color: transparent !important;
}

</style>