import request from '@/utils/request';

// 历史告警
export function getDataAsArea (data, businessId) {
  const rand = Date.now();

  let url = "devices/area?" + Date.now();
  
  if (businessId) {
    url += `&businessId=${businessId}`;
  }

  if (data) {
    url += `&startTime=${data.startTime}&uploadTime=${data.uploadTime}`;
  }

  return request.get(url);
}

// 设备在线率
export function getStatusCount (data, businessId) {
  let url = "devices/Status/count?" + Date.now();
  if (businessId) {
    url += `&businessId=${businessId}`;
  }
  return request.get(url, data);
}

// 设备增长曲线
export function getMonthCount (data) {
  const rand = Date.now();
  if (data) {
    return request.get(`devices/month/count?${rand}&startTime=${data.startTime}&uploadTime=${data.uploadTime}`);
  } else {
    return request.get(`devices/month/count?${rand}&`);
  }
}

// 全国设备在线量
export function getNationwideCount (data, businessId) {
  const rand = Date.now();

  let url = "devices/nationwide/count?" + Date.now();

  if (businessId) {
    url += `&businessId=${businessId}`;
  }

  return request.get(url, data);
}

// 全国前五
export function getFiveArea () {
  const rand = Date.now();
  let url = `devices/fiveArea?${rand}`;

  return request.get(url);
}