var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading.global,
          expression: "loading.global",
        },
      ],
      staticClass: "body no-select",
    },
    [
      _c("div", { staticClass: "head-box" }, [
        _c(
          "div",
          { staticClass: "to-page-btn" },
          [
            _c("el-button", {
              attrs: { type: "primary", size: "small", icon: "el-icon-s-home" },
              on: { click: _vm.onToWeb },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "head-title" }, [_vm._v("智能监控数据大屏")]),
        _c("div", { staticClass: "head-timer", attrs: { id: "timer" } }, [
          _vm._v(_vm._s(_vm.nowTime)),
        ]),
      ]),
      _c("div", { staticClass: "main-box" }, [
        _c("div", { staticClass: "left-box" }, [
          _c(
            "div",
            { staticClass: "data-screen-select" },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "当前省份" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { size: "mini" },
                          on: { change: _vm.onSelectProv },
                          model: {
                            value: _vm.selectedProv,
                            callback: function ($$v) {
                              _vm.selectedProv = $$v
                            },
                            expression: "selectedProv",
                          },
                        },
                        _vm._l(_vm.provList, function (prov, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: prov, value: prov },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "left-list-box" }, [
            _c("div", { staticClass: "frame-title-box frame-txt-title-box" }, [
              _vm._v("设备在线时间"),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading.devices,
                    expression: "loading.devices",
                  },
                ],
                staticClass: "frame-data-box left-table-box",
                staticStyle: { height: "calc(100% - 5.3vh)" },
              },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.deviceList },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "information",
                        width: "150",
                        label: "设备名称",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.information || scope.row.code
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "hours",
                        label: "运行时间(小时)",
                        "min-width": "110",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createTime",
                        label: "上线时间",
                        "min-width": "150",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "center-box" }, [
          _c("div", { staticClass: "map-box" }, [
            _c("div", {
              ref: "mapChart",
              style: `width:${_vm.map.width}px; height:${_vm.map.height}px`,
            }),
          ]),
          _c("div", { staticClass: "device-statistic-box" }, [
            _c("div", { staticClass: "device-statistic-title-box" }, [
              _vm._v("设备统计"),
            ]),
            _c("div", { staticClass: "device-statistic-val-box" }, [
              _c("div", [
                _c("div", [_vm._v("所有设备")]),
                _c("div", [_vm._v(_vm._s(_vm.deviceCnts.total))]),
              ]),
              _c("div", [
                _c("div", [_vm._v("在线设备")]),
                _c("div", [_vm._v(_vm._s(_vm.deviceCnts.online))]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "right-box" }, [
          _c(
            "div",
            {
              staticClass: "right-data-box",
              style: `height:calc(${_vm.right.height}px + 8vh); margin-bottom:8vh;`,
            },
            [
              _c(
                "div",
                { staticClass: "frame-title-box frame-txt-title-box" },
                [_vm._v("设备在线率")]
              ),
              _c("div", { staticClass: "frame-data-box" }, [
                _c(
                  "div",
                  { style: `height:calc(${_vm.right.height}px + 8vh);` },
                  [
                    _c("div", {
                      ref: "deviceOnlineChart",
                      style: `width:${_vm.right.width - 265}px; height:calc(${
                        _vm.right.height
                      }px + 5vh);`,
                    }),
                  ]
                ),
                _c("div", { staticClass: "right-legend-box" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "device-base-data-box" }, [
                    _c("div", [_vm._v("在线数量")]),
                    _c("div", [_vm._v(_vm._s(_vm.deviceCnts.online) + " 台")]),
                  ]),
                  _c("div", { staticClass: "device-base-data-box" }, [
                    _c("div", [_vm._v("离线数量")]),
                    _c("div", [_vm._v(_vm._s(_vm.deviceCnts.offline) + " 台")]),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "right-data-box",
              style: `height:calc(${_vm.right.height}px + 8vh); margin-bottom:7vh;`,
            },
            [
              _c(
                "div",
                { staticClass: "frame-title-box frame-txt-title-box" },
                [_vm._v("设备告警率")]
              ),
              _c("div", { staticClass: "frame-data-box" }, [
                _c(
                  "div",
                  { style: `height:calc(${_vm.right.height}px + 8vh);` },
                  [
                    _c("div", {
                      ref: "deviceWarningChart",
                      style: `width:${_vm.right.width - 265}px; height:calc(${
                        _vm.right.height
                      }px + 5vh);`,
                    }),
                  ]
                ),
                _c("div", { staticClass: "right-legend-box" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "device-base-data-box" }, [
                    _c("div", [_vm._v("告警数量")]),
                    _c("div", [_vm._v(_vm._s(_vm.deviceCnts.warning) + " 台")]),
                  ]),
                  _c("div", { staticClass: "device-base-data-box" }, [
                    _c("div", [_vm._v("设备数量")]),
                    _c("div", [_vm._v(_vm._s(_vm.deviceCnts.total) + " 台")]),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "right-last-box",
              style: `height:calc(100% - (${_vm.right.height}px + 8vh) * 2 - 15vh);`,
            },
            [
              _c("div", { staticStyle: {} }, [
                _vm._m(2),
                _c("div", { staticStyle: {} }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        overflow: "hidden",
                        height: "calc(100% - 10px)",
                      },
                    },
                    [
                      _c(
                        "vue-seamless-scroll",
                        {
                          staticClass: "seamless-warp",
                          attrs: {
                            data: _vm.warningData,
                            "class-option": _vm.classOption,
                          },
                        },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                data: _vm.warningData,
                                "show-header": false,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { prop: "productId", width: "70" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "createTime", width: "155" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "warningData" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.warning == null
                                          ? _c("div")
                                          : scope.row.warning.length > 0
                                          ? _c("div", [
                                              scope.row.warning[0]
                                                ? _c("span", [
                                                    _vm._v("总压过高一级告警 "),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[1]
                                                ? _c("span", [
                                                    _vm._v("总压过高二级告警 "),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[2]
                                                ? _c("span", [
                                                    _vm._v("总压过高三级告警 "),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[3]
                                                ? _c("span", [
                                                    _vm._v("总压过低一级告警 "),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[4]
                                                ? _c("span", [
                                                    _vm._v("总压过低二级告警 "),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[5]
                                                ? _c("span", [
                                                    _vm._v("总压过低三级告警 "),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[6]
                                                ? _c("span", [
                                                    _vm._v("单体过高一级告警 "),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[7]
                                                ? _c("span", [
                                                    _vm._v("单体过高二级告警 "),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[8]
                                                ? _c("span", [
                                                    _vm._v("单体过高三级告警 "),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[9]
                                                ? _c("span", [
                                                    _vm._v("单体过低一级告警 "),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[10]
                                                ? _c("span", [
                                                    _vm._v("单体过低二级告警 "),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[11]
                                                ? _c("span", [
                                                    _vm._v("单体过低三级告警 "),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[12]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "高段压差过高一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[13]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "高段压差过高二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[14]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "高段压差过高三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[15]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "中段压差过高一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[16]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "中段压差过高二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[17]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "中段压差过高三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[18]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "低段压差过高一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[19]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "低段压差过高二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[20]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "低段压差过高三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[21]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "辅助压差过高一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[22]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "辅助压差过高二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[23]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "辅助压差过高三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[24]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "充电电流过高一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[25]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "充电电流过高二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[26]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "充电电流过高三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[27]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "放电电流过高一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[28]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "放电电流过高二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[29]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "放电电流过高三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[30]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "反充电流过高一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[31]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "反充电流过高二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[32]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "反充电流过高三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[33]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "SOC值过低一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[34]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "SOC值过低二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[35]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "SOC值过低三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[36]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "SOH值过低一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[37]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "SOH值过低二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[38]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "SOH值过低三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[39]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "继电器温度过高一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[40]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "继电器温度过高二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[41]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "继电器温度过高三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[42]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "充电温度过高一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[43]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "充电温度过高二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[44]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "充电温度过高三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[45]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "放电温度过高一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[46]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "放电温度过高二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[47]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "放电温度过高三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[48]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "充电温度过低一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[49]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "充电温度过低二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[50]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "充电温度过低三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[51]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "放电温度过低一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[52]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "放电温度过低二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[53]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "放电温度过低三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[54]
                                                ? _c("span", [
                                                    _vm._v("温差过大一级告警 "),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[55]
                                                ? _c("span", [
                                                    _vm._v("温差过大二级告警 "),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[56]
                                                ? _c("span", [
                                                    _vm._v("温差过大三级告警 "),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[57]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "均衡温度过高一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[58]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "均衡温度过高二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[59]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "均衡温度过高三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[60]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "绝缘电阻过低一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[61]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "绝缘电阻过低二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[62]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "绝缘电阻过低三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[63]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "总压采样异常一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[64]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "总压采样异常二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[65]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "总压采样异常三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[66]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "静置温度过高一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[67]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "静置温度过高二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[68]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "静置温度过高三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[69]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "静置温度过低一级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[70]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "静置温度过低二级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[71]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "静置温度过低三级告警 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[72]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "主正接触器无法吸合 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[73]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "主正接触器粘连故障 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[74]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "主负接触器无法吸合 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[75]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "主负接触器粘连故障 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[76]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "充电接触器无法吸合 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[77]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "充电接触器粘连故障 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[78]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "预热接触器无法吸合 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[79]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "预热接触器粘连故障 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[80]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "风扇接触器无法吸合 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[81]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "风扇接触器粘连故障 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[82]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "预充接触器无法吸合 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              scope.row.warning[83]
                                                ? _c("span", [
                                                    _vm._v(
                                                      "预充接触器粘连故障 "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ])
                                          : _c("div", [_vm._v("设备无告警")]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "device-base-data-box" }, [
      _c("div", [_c("div", { staticClass: "device-online-box" })]),
      _c("div", [_vm._v("在线")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "device-base-data-box" }, [
      _c("div", [_c("div", { staticClass: "device-warning-box" })]),
      _c("div", [_vm._v("告警")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "frame-txt-title-box" }, [_vm._v("告警信息")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }